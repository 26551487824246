import Link from "next/link";
import { get } from "lodash";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import { scrollToTop } from "grandus-lib/utils";

import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

import styles from "components/product/card/ProductCard.module.scss";
import { useRouter } from "next/router";

const ProductCard = ({ options = {}, ...product }) => {
  const { name, urlTitle, storeStatus, finalPriceData, photo } = product;
  const router = useRouter();
  const onClickHandler = (e) => {
    e.preventDefault();
    TagManager.push(
      EnhancedEcommerce.productClick(
        { ...product, ...{ position: get(options, "eecProduct.position") } },
        "category",
        {
          page: get(options, "eecProduct.page"),
          perPage: get(options, "eecProduct.perPage"),
        }
      ),
      () => {
        router
          .push("/produkt/[id]", `/produkt/${urlTitle}`)
          .then(() => scrollToTop());
      }
    );
  };
  return (
    <div
      className={[styles.box, ...get(options, "style.wrapperClasses", [])].join(
        " "
      )}
    >
      <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`} scroll={true}>
        <a onClick={onClickHandler}>
          <div className={styles.badges}>
            {get(product, "new") ? (
              <div className={styles.badgeNew}>
                <span>Výhodné</span>
              </div>
            ) : (
              ""
            )}

            {get(product, "favourite") ? (
              <div className={styles.badgeFavourite}>
                <span>Obľúbené</span>
              </div>
            ) : (
              ""
            )}

            {/* {storeStatus?.available ? (
              <div className={styles.badgeDelivery}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.772"
                    height="15.458"
                    viewBox="0 0 23.772 15.458"
                  >
                    <path
                      d="M743.347,23.864h-12.68V24.95h11.594v8.273H730.667v1.086h12.68Z"
                      transform="translate(-727.102 -23.864)"
                    />
                    <path
                      d="M788.824,30.985l-2.089-3.663h-3.922v9.626H789.7V31.486ZM786.1,28.408l1.309,2.3H783.9v-2.3Zm2.511,7.454H783.9V31.789h4.143l.243.139.33.188Z"
                      transform="translate(-765.929 -26.439)"
                    />
                    <path
                      d="M735.116,67.849a2.112,2.112,0,1,0,2.112,2.112A2.112,2.112,0,0,0,735.116,67.849Zm0,3.138a1.026,1.026,0,1,1,1.026-1.026A1.027,1.027,0,0,1,735.116,70.987Z"
                      transform="translate(-728.842 -56.615)"
                    />
                    <path
                      d="M781.9,67.849a2.112,2.112,0,1,0,2.112,2.112A2.112,2.112,0,0,0,781.9,67.849Zm0,3.138a1.026,1.026,0,1,1,1.026-1.026A1.027,1.027,0,0,1,781.9,70.987Z"
                      transform="translate(-763.675 -56.615)"
                    />
                    <rect
                      width="7.982"
                      height="1.086"
                      transform="translate(1.831 2.261)"
                    />
                    <rect
                      width="7.982"
                      height="1.086"
                      transform="translate(0 4.453)"
                    />
                    <rect
                      width="7.982"
                      height="1.086"
                      transform="translate(1.653 6.646)"
                    />
                  </svg>
                  do 24h.
                </span>
              </div>
            ) : (
              ""
            )} */}

            {get(product, "status") ? (
              <div className={styles.badgeStatus}>
                <span>{product.status}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.productThumb}>
            <Image
              photo={photo}
              size={"269x200"}
              type={"jpg"}
            />
            {product?.discount ? (
              <div
                className={[styles.productDiscountBadge, "discountBadge"].join(
                  " "
                )}
              >
                <span>
                  Ušetri až <br />
                  <strong>{product?.discount}</strong>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.infoBarWrapper}>
            <span className={styles.infoStock}>{storeStatus?.message}</span>
            <span className={styles.infoStockMobile}>
              {storeStatus?.message}
            </span>
            <div className={styles.productActions}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.536"
                  height="16.896"
                  viewBox="0 0 23.536 16.896"
                >
                  <path
                    d="M238.467,329.963h-4.744a2.22,2.22,0,0,0-4.24,0h-4.744s-4.9,7.721-4.9,10.43a4.9,4.9,0,1,0,9.808,0c0-2.013-2.707-6.791-4.1-9.115h3.938a2.22,2.22,0,0,0,4.24,0h3.938c-1.391,2.323-4.1,7.1-4.1,9.115a4.9,4.9,0,1,0,9.808,0C243.371,337.684,238.467,329.963,238.467,329.963Zm-13.728,2.525a35.992,35.992,0,0,1,3.489,7.248H221.25A35.993,35.993,0,0,1,224.739,332.488Zm0,11.494a3.592,3.592,0,0,1-3.525-2.931h7.051A3.593,3.593,0,0,1,224.739,343.982Zm6.864-12.457a.9.9,0,1,1,.9-.9A.906.906,0,0,1,231.6,331.525Zm10.453,8.868c0,.014,0,.028,0,.042v-.069C242.054,340.374,242.055,340.385,242.055,340.393Zm-.1-.658h-6.979a35.993,35.993,0,0,1,3.489-7.248A36.011,36.011,0,0,1,241.956,339.735Zm-3.489,4.246a3.593,3.593,0,0,1-3.526-2.931h7.051A3.593,3.593,0,0,1,238.467,343.982Z"
                    transform="translate(-219.835 -328.401)"
                  />
                </svg>
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.989"
                  height="16.952"
                  viewBox="0 0 19.989 16.952"
                >
                  <path
                    d="M369.847,198.115l-8.2-7.528a5.331,5.331,0,0,1,0-7.972,5.767,5.767,0,0,1,7.65,0l.554.508.553-.508a5.769,5.769,0,0,1,7.65,0h0a5.333,5.333,0,0,1,0,7.972Zm-4.379-15.069a3.761,3.761,0,0,0-2.544.965,3.438,3.438,0,0,0,0,5.18l6.923,6.352,6.922-6.352a3.438,3.438,0,0,0,0-5.179h0a3.836,3.836,0,0,0-5.088,0l-1.834,1.683-1.835-1.683A3.758,3.758,0,0,0,365.468,183.046Z"
                    transform="translate(-359.852 -181.163)"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className={styles.contentCentered}>
            <h4>{name}</h4>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: product?.shortDescription,
              }}
            />
            <div className={styles.priceWrapper}>
              <div className={styles.mainPrice}>
                <span>{finalPriceData?.priceFormatted}</span>
                {finalPriceData?.priceWithoutVatFormatted ? (
                  <span>
                    {finalPriceData?.priceWithoutVatFormatted} bez DPH
                  </span>
                ) : (
                  ""
                )}
              </div>
              {product?.standardPriceData?.priceFormatted ? (
                <div className={styles.discountPrice}>
                  <span>{product?.standardPriceData?.priceFormatted}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ProductCard;
